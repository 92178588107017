// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade
import queryString from 'query-string';
import { isNil } from 'ramda';
import { isBlank } from 'utils/Utils';

export default {
  adminEditOrganizationsPath: id => `/admin/organizations/${id}/edit`,
  adminFailingRostersPath: () => '/failing_rosters',
  adminBasePath: () => '/new/admin',
  adminMapperPath: (uuid, params) => `/mapper/${uuid}?${queryString.stringify(params)}`,
  adminOrganizationMapperPath: id => `/organization_mapper/${id}`,
  adminProvidersPath: () => '/providers',
  adminRcesPath: () => `/rces`,
  adminTrialsPath: () => '/trials',

  // audits
  adminAuditsPath: () => '/audits',
  adminAuditPath: id => `/audits/${id}`,

  // companies
  adminCompaniesPath: () => '/companies',
  adminEditCompanyPath: id => `/companies/${id}/edit`,
  adminNewCompanyPath: () => `/companies/new`,

  // Datasources
  adminDataSourcesPath: () => '/data_sources',
  adminDataSourceDetailPath: dataSource => `/data_sources/${dataSource}`,
  adminDataSourceJamfPath: () => '/data_sources/jamf',
  adminDataSourcesProductRequestsPath: () => '/data_sources/product_requests',
  adminDataSourceSdpcPath: () => '/data_sources/sdpc',
  adminDataSourceSdpcOrganizationsPath: () => '/data_sources/sdpc_orgs',
  adminDataSourcesTrustedAppsPath: () => '/data_sources/trusted_apps',

  adminEacDataPath: () => '/data_sources/maintenance_hub/eac_data',
  adminMarketplacePath: () => '/data_sources/maintenance_hub/marketplace',
  adminLtiMapperPath: () => '/data_sources/maintenance_hub/lti_mapper',
  adminMaintenancePath: () => '/data_sources/maintenance_hub/tools',
  adminMaintenanceToolPath: toolId => `/data_sources/maintenance_hub/tool/${toolId}`,


  adminDataSourcesFindMatchingProductForNewProductPath: (requestId, params) => {
    const path = `/data_sources/product_requests/${requestId}/find_matching_product`;
    if (isBlank(params)) return path;
    return `${path}?${queryString.stringify(params)}`;
  },

  adminDataSourcesProductRequestsNewToolPath: (requestId, params) => {
    const path = `/data_sources/product_requests/${requestId}/tools/new`;
    if (isBlank(params)) return path;

    return `${path}?${queryString.stringify(params)}`;
  },

  // discounts
  adminDiscountPath: () => '/discount_codes',
  adminEditDiscountPath: id => `/discount_codes/${id}/edit`,
  adminNewDiscountPath: () => `/discount_codes/new`,

  // email
  adminEmailsPath: () => '/emails',
  adminEmailPath: id => `/emails/${id}`,

  // form_template
  adminFormTemplatesPath: () => '/form_templates',
  adminEditFormTemplatePath: id => `/form_templates/${id}/edit`,
  adminNewFormTemplatePath: () => `/form_templates/new`,

  // groups
  adminGroupsPath: () => '/groups',
  adminEditGroupPath: id => `/groups/${id}/edit`,

  // products
  adminProductsPath: () => '/products',
  adminEditProductPath: id => `/products/${id}/edit`,
  adminNewProductPath: () => `/products/new`,

  // organizations
  adminOrganizationsPath: () => '/organizations',
  adminEditOrganizationPath: id => `/organizations/${id}/edit`,
  adminOrganizationUploadsPath: id => `/organizations/${id}/uploads`,
  adminOrganizationGenerateOneMetaPath: id => `/organizations/${id}/generate_one_meta`,
  adminNewOrganizationPath: params => {
    const newOrganizationPath = '/organizations/new';
    return isNil(params) ? newOrganizationPath : `${newOrganizationPath}?${queryString.stringify(params)}`;
  },

  // subscriptions
  adminSubscriptionsPath: () => '/subscriptions',
  adminNewSubscriptionPath: () => '/subscriptions/new',
  adminEditSubscriptionPath: id => `/subscriptions/${id}/edit`,

  // tags
  adminTagsPath: () => '/tags',
  adminEditTagPath: id => `/tags/${id}/edit`,
  adminNewTagPath: () => `/tags/new`,

  // tag filters
  adminTagFiltersPath: () => '/tag_filters',
  adminEditTagFilterPath: id => `/tag_filters/${id}/edit`,
  adminNewTagFilterPath: () => `/tag_filters/new`,

  // tag groups
  adminTagGroupsPath: () => '/tag_groups',
  adminEditTagGroupPath: id => `/tag_groups/${id}/edit`,
  adminNewTagGroupPath: () => `/tag_groups/new`,

  // users
  adminUsersPath: () => '/users',
  adminEditUserPath: id => `/users/${id}/edit`,
  adminNewUserPath: () => `/users/new`,

};
